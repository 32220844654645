@import 'styles/css/main';

.container {
  width: 100%;
  height: 5px;
  position: relative;
  border-radius: 8px;

  .innerTrack {
    position: absolute;
    height: 5px;
    border-radius: 8px 0px 0px 8px;
  }

  .baseTrack {
    width: 100%;
    background-color: #d0dff7;
    border-radius: 8px;
    z-index: 1;
  }

  .sucessTrack {
    background-color: #2067f3;
    z-index: 3;
  }

  .errorTrack {
    background-color: #f5475e;
    border-radius: 8px;
    z-index: 2;
  }
}
