@import 'styles/css/main';

.container {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  .iconContainer {
    .icon {
      font-size: 0.75rem;
      font-weight: 900;
      line-height: 0.75rem;
      padding: 4px;
    }

    .successIcon {
      color: #059669;
    }

    .pendingIcon {
      color: #2563eb;
      @include spin;
    }

    .failedIcon {
      color: #da1b41;
    }
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .titleText {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: #324452;
    }

    .viewImageButton {
      color: #144aeb;
      border-color: #144aeb;
      margin-top: 4px;
    }

    .startTimeStamp {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #486075;
    }
  }
}
