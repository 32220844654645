@import 'styles/css/main';

.container {
  z-index: 1400 !important;
}

.root {
  width: 392px;
  .header {
    padding: 16px 12px 12px 12px;
    border-bottom: 1px solid #e8f2fe;
    @include flexBetween;

    p {
      font-size: 1rem;
      font-weight: 600;
      color: #324452;
      line-height: 1.25rem;
    }

    .closeIcon {
      font-size: 1.125rem;
      font-weight: 400;
      color: #3e5680;
      cursor: pointer;
    }
  }

  .body {
    padding: 16px 12px 20px 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .radioLabel {
      color: #212a31;
    }

    .radioSubLabel {
      color: #627c93;
    }
  }

  .actionBtnContainer {
    padding: 12px 20px;
    background-color: #f0f7ff;
    display: flex;
    align-items: center;
  }
}
