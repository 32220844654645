@import 'styles/css/main';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .detailContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      color: #486075;

      .percentageValue {
        font-weight: 600;
      }
    }

    .failedContainer {
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        color: #f5475e;
      }
    }
  }
}
