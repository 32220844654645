@import 'src/styles/css/main';

.truncateText {
  width: 100%;
  overflow: hidden;
  @include hideScrollbar;
  background-color: inherit;
  white-space: nowrap;

  display: flex;

  &.copyLabel {
    cursor: pointer;
    user-select: none;

    &:hover {
      .icon {
        visibility: visible;
      }
    }
  }

  .label2 {
    position: relative;
    background-color: inherit;
  }

  .overflow {
    padding-right: 8px;

    &::before {
      content: '...';
    }
  }

  .icon {
    margin-left: 6px;
    align-self: center;
    visibility: hidden;
  }
}
