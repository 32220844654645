@import 'src/styles/css/colors';
@import 'src/styles/css/_const';

.floatingStatusWidget {
  border: 0.4px solid $colorSecondary;
  box-shadow: 0px 10px 15px -3px rgba(14, 22, 35, 0.1),
    0px 4px 6px -2px rgba(14, 22, 35, 0.05);
  border-radius: 6px;
  width: 100%;
  background-color: $white;
  z-index: 1300;

  & > div:first-child {
    padding: $small $med;
  }

  & > div:nth-child(2) > div {
    padding: $xxSmall $large;
  }
  & > div:nth-child(2) > div {
    border-bottom: 1px solid #f4f5f6;
  }

  & > div > div:last-child {
    border-bottom: none;
  }

  .floatingStatusWidgetBody {
    transition: all 200ms;
    overflow: hidden;

    &.expanded {
      border-top: 1px solid $colorNonary;
      max-height: 60vh;
      overflow-y: auto;
    }

    &.minimised {
      max-height: 0vh;
    }
  }
}
