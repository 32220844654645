@import 'src/styles/css/_const';
@import 'src/styles/css/_main';

.statusCard {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  .iconContainer {
    .icon {
      font-size: 0.75rem;
      font-weight: 900;
      line-height: 0.75rem;
      padding: 4px;
    }

    .successIcon {
      color: #059669;
    }

    .pendingIcon {
      color: #2563eb;
      @include spin;
    }

    .failedIcon {
      color: #da1b41;
    }
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .retryButton {
      color: #da1b41;
      border-color: #da1b41;

      svg {
        color: #da1b41;
      }
    }
  }

  .error {
    display: flex;
  }

  .name {
    max-width: 70%;
  }

  span {
    font-size: 13px;
    font-weight: $fontWeightMed;
  }

  .rotate {
    @include spin(2s);
  }

  .retryButton {
    cursor: pointer;
  }
}
